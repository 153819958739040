<template>
  <div>
    <!-- <save
      ref="saveForm"
      @onSuccess="getItems"
    /> -->
    <h2>Xodimlar bo'yicha KPI</h2>
    <b-row>
      <b-col
        :md="7"
        class="d-flex flex-wrap flex-row"
      >
        <x-date-picker
          v-if="filterModel.from_date"
          v-model="filterModel.from_date"
          :placeholder="$t('Дата начала')"
          class="mr-1 mb-1"
        />
        <x-date-picker
          v-if="filterModel.to_date"
          v-model="filterModel.to_date"
          :placeholder="$t('Дата окончания')"
          class="mr-1 mb-1"
        />
      </b-col>
      <b-col :md="1">
        <!-- <label label="filter_data">Tozalash</label> -->
        <b-button
          variant="primary"
          class="btn-tour-skip mr-1"
          @click="getItems"
        >
          Hisoblash
        </b-button>
      </b-col>
    </b-row>
    <b-overlay :show="loading">
      <good-table-column-search
        :items="items"
        :columns="columns"
        :total="items.total"
        :page="page"
        :filter="filterModel"
        @getItems="getItems"
        @add="$refs.saveForm.visible = true"
        @edit="(item) => $refs.saveForm.edit(item)"
        @onPageChange="(p) => page = p"
      >
        <template
          slot="table-column"
          slot-scope="props"
        >
          <span v-if="props.column.field === 'salary'">
            {{ calculateSalary(props.row) |formatPrice }}
          </span>

        </template>
      </good-table-column-search>
    </b-overlay>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import GoodTableColumnSearch from '@/views/table/vue-good-table/GoodTableColumnSearch.vue'

export default {
  name: 'Index',
  components: {
    GoodTableColumnSearch,
  },
  data() {
    return {
      loading: false,
      items: [],
      page: 1,
      contract_plus_percent: null,
      cash_plus_percent: null,
      cash_minus_per_amount: null,
      filterModel: {
        from_date: null,
        per_page: 9000,
        to_date: null,
      },
      columns: [
        {
          label: '#',
          field: 'row_number',
        },
        {
          label: 'Xodim',
          field: 'name',
        },
        {
          label: 'Naqd savdodan foyda',
          field: 'profit_amount_cash',
          isAmount: true,
        },
        {
          label: 'Zarar(naqd)ga sotilgan mahsulot soni',
          field: 'count_minus',
          isAmount: true,

        },
        {
          label: 'Muddatli to`lovdan foyda',
          field: 'profit_amount_contract',
          isAmount: true,

        },
        {
          label: 'Oklad',
          field: 'oklad',
          isAmount: true,

        },
        {
          label: 'Hisoblangan oylik',
          field: 'salary',
        },
      ],
    }
  },
  computed: {
  },
  watch: {
  },

  mounted() {
    const dd = new Date()
    const firstDay = new Date(dd.getFullYear(), dd.getMonth(), 1)
    const lastDay = new Date(dd.getFullYear(), dd.getMonth() + 1, 0)
    console.log(firstDay, this.formatDate(firstDay))

    this.filterModel.from_date = this.formatDate(firstDay)
    this.filterModel.to_date = this.formatDate(lastDay)

    this.fetchSettings().then(res => {
      this.contract_plus_percent = res.data.data.filter(
        c => c.code == 'contract_plus_percent',
      )[0].value
      this.cash_plus_percent = res.data.data.filter(
        c => c.code == 'cash_plus_percent',
      )[0].value
      this.cash_minus_per_amount = res.data.data.filter(
        c => c.code == 'cash_minus_per_amount',
      )[0].value
    })
    this.getItems()
  },
  methods: {
    ...mapActions({
      getItemsAction: 'resource/kpiEmployee',
      fetchSettings: 'setting/index',
    }),
    calculateSalary(data) {
      const countMinus = data.count_minus ? data.count_minus * Number(this.cash_minus_per_amount) / 100 : 0
      const profitAmountCash = data.profit_amount_cash ? data.profit_amount_cash * Number(this.cash_plus_percent) / 100 : 0
      const profitAmountContract = data.profit_amount_contract ? data.profit_amount_contract * Number(this.contract_plus_percent) / 100 : 0
      return countMinus + profitAmountCash + profitAmountContract + data.oklad
    },
    formatDate(date) {
      const today = new Date(date)
      const dd = String(today.getDate()).padStart(2, '0')
      const mm = String(today.getMonth() + 1).padStart(2, '0') // January is 0!
      const yyyy = today.getFullYear()
      return `${yyyy}-${mm}-${dd}`
    },
    async getItems() {
      this.loading = true
      await this.getItemsAction({ ...this.filterModel }).then(res => {
        this.items = res.data
      }).finally(() => {
        this.loading = false
      })
    },

  },
}
</script>

<style scoped></style>
